import React, { memo, useCallback } from 'react';
import { MenuList, MenuItem } from '@material-ui/core';
import { NavLink, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES } from 'constants/routes';
import { trackNavToDataValidation } from 'ducks/trackers/actions/navigation';
import ProjectHeader from 'containers/projects/ProjectHeader';
import { selectProjectById } from 'ducks/projects/selectors';

import { useStyles } from './styles';

/**
 * ProjectMenu render project menu
 * @param {string} projectId
 * @param {boolean} hasAWD
 */
const ProjectMenu = ({ projectId, hasAWD = true }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { status } = useSelector((state) =>
    selectProjectById(state, projectId)
  );

  const path = generatePath(ROUTES.project, { id: projectId });
  const workzonePath = status === "FINISHED" ? `${path}/analyze` : path;

  console.log(status)

  // track click on "data validation"
  const dispatch = useDispatch();
  const sendTrackingData = useCallback(() => {
    dispatch(trackNavToDataValidation(projectId));
  }, [dispatch, projectId]);

  return (
    <MenuList className={classes.menu}>
      <div className={classes.status}>
        <ProjectHeader />
      </div>
      <MenuItem className={classes.item}>
        <NavLink
          to={workzonePath}
          className={classes.link}
        >
          {t('projects.menu.workzone')}
        </NavLink>
      </MenuItem>
      <div>&#124;</div>
      <MenuItem className={classes.item}>
        <NavLink
          exact
          to={generatePath(ROUTES.dataValidation, { id: projectId })}
          onClick={sendTrackingData}
          className={classes.link}
        >
          {t('projects.menu.datavalidation')}
        </NavLink>
      </MenuItem>
      <div>&#124;</div>
      {hasAWD && (
        <MenuItem className={classes.item}>
          <NavLink
            exact
            to={generatePath(ROUTES.downtime, { id: projectId })}
            className={classes.link}
          >
            {t('projects.menu.advanced')}
          </NavLink>
        </MenuItem>
      )}
    </MenuList>
  );
};
ProjectMenu.propTypes = {
  projectId: PropTypes.string.isRequired,
  hasAWD: PropTypes.bool,
};

export default memo(ProjectMenu);
